.checkout-modal {
    text-align: center;
    padding: 2.75rem 3rem;
}
.checkout-modal h2 {
    color:  #009E8C;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.text-btn {
    background: #009E8C;
    color: #fff;
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
}
.text-btn:disabled {
    opacity: 0.75;
    cursor: not-allowed;
}
.unorder-list {
    display: flex;
}
.unorder-list li {
    background: #fff;
    color: #009E8C;
    margin: 0;
    cursor: pointer;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border: 0.0625rem solid #009E8C;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
}
.raise-collaction-request, .raise-collaction-request-successfully {
    background: #fff;
    width: 36.375rem;
    padding: 2.5rem 3rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.raise-collaction-request h2, .raise-collaction-request-successfully h2 {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.375rem;
    font-weight: 500;
}
.raise-collaction-request .ordered-btn, .raise-collaction-request .green-btn, .raise-collaction-request-successfully .ordered-btn, .raise-collaction-request-successfully .green-btn  {
    padding: 0.875rem 3.5rem;
    cursor: pointer;
}

.document-submitted-modal {
    background: #fff;
    width: 36.375rem;
    padding: 2.5rem 3rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.document-submitted-modal h1 {
    color: #009E8C;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 500;
}
.document-submitted-modal p {
    color: #616161;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
}
.document-submitted-modal .ordered-btn, .document-submitted-modal .green-btn {
    padding: 0.875rem 3.5rem;
    cursor: pointer;
}