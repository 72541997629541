.survey-taken {
    background: #F8F8F8;
    width: 100%;
    margin-top: 1.5rem;
    padding: 1.25rem 1rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.12);
    display: flex;
}
.survey-taken:first-child {
    margin-top: 2rem;
}
.survey-taken h4 {
    color: #009E8C;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem; 
}
.survey-taken span {
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; 
}
.survey-taken p {
    color: #424242;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.5rem 0 1rem 0;
}