.partnership .banner {
    background: url(../../asset/images/ngo-hdr.jpg) no-repeat;
    background-size: cover;
}
.partnership .banner h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 4rem 1rem 0 1rem;
}

@media only screen and (min-width: 768px) {
    .partnership .banner h1 {
        font-size: 3.375rem;
        margin: 19rem 1rem 0 1rem;
    }
}