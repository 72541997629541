.notification-section {
    background: #fff;
    padding: 1rem;
    -moz-border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
}
.notification-section h1 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.notification-section .count {
    background: #4CAF50;
    color: #FFF;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: inline-flex;
    margin: 0 0 0 0.75rem;
    padding: 0.125rem 0.5rem;
    -moz-border-radius: 0.5625rem;
    -webkit-border-radius: 0.5625rem;
    border-radius: 0.5625rem;
}
.notification-section li {
    background: #F8F8F8;
    margin: 1rem 0 0 0;
    padding: 0.75rem;
    box-shadow: -0.125rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.12), 0.125rem 0 0.25rem 0 rgba(0, 0, 0, 0.12);
    -moz-border-radius: 0.1875rem;
    -webkit-border-radius: 0.1875rem;
    border-radius: 0.1875rem;
}
.notification-section li h2 {
    color: #009E8C;
    font-size: 0.65625rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.9375rem; 
    display: flex;
}
.notification-section li svg {
    margin-right: 0.375rem;
}
.notification-section li p {
    color: #424242;
    font-size: 0.5625rem;
    font-style: normal;
    font-weight: 400;
    line-height: 0.9375rem;
    margin: 0.375rem 0 0.375rem 1.25rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.notification-section .green-btn {
    width: 100%;
    text-align: center;
    padding: 0.5rem 1.5rem;
}