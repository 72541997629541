.community .banner {
    background: url(../../asset/images/community.jpg) no-repeat;
    background-size: cover;
}
.community .banner h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2rem 0 0 1rem;
}
.common-description {
    padding: 2rem 0;
}
.common-description h1 {
    color: #008d80;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
}
.wrapper {
    width: 90%;
    margin: 0 auto;
}
.common-description p {
    font-size: 1rem;
    color: #4a4a4a;
    margin: 1.5rem auto;
    text-align: center;
}
.common-description h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}
.thumbnail-carousel .item {
    padding: 0 1.25rem;
}
.thumbnail-carousel .item img {   
    height: 21.25rem; 
    border-left: 0.5rem solid #00a892;
    border-right: 0.5rem solid #00a892;
}
.thumbnail-carousel .arrow-left {
    font-size: 2rem;
    position: absolute;
    left: -0.25rem;
    cursor: pointer;
}
.thumbnail-carousel .arrow-right {
    font-size: 2rem;
    position: absolute;
    right: -0.25rem;
    cursor: pointer;
}
.recyle-process {
    width: 100%;
    padding: 1.25rem 0;
}
.recyle-process h3 {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    margin: 1.25rem 0 1rem 0;
}
.recyle-process .grey-block {
    background: #eaf1f0;
    padding: 1.5rem;
}
.recyle-process .grey-block img {
    margin: 0 auto;
}
.join-initiative {
    padding: 1rem 1rem 5rem 1rem;
}
.join-initiative h4 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;    
}
.join-initiative label {
    font-size: 1.125rem;
}
.join-initiative label span {
    color: red;
}
.join-initiative .form-control {
    width: 100%;
    padding: 0.5rem;
    border: 0.0625rem solid #000;
}
.join-initiative .link {
    color: #337ab7;
}
.app-section {
    background-color: #01ad9d;
    margin: 3rem auto;
    padding: 1rem 1.5rem;
    -moz-border-radius: 0.625rem;
    -webkit-border-radius: 0.625rem;
    border-radius: 0.625rem;
    box-shadow: 0.0625rem 0.125rem 0.3125rem rgba(52,52,52,0.5);
    flex-direction: column;
    display: flex;
}
.app-section .app-img {
    width: 100%;
    margin-bottom: 1rem;
}
.app-section .app-img img {
    width: 4.5rem;
    margin: 0 auto;
}
.app-section .app-text {
    width: 100%;
    text-align: center;
}
.app-section .app-text h5 {
    color: #fff;
    font-size: 0.875rem;
}
.app-section .app-text .download-btn {
    background: gold;
    width: 100%;
    font-size: 1rem;
    margin: 0.5625rem 0;
    padding: 0.75rem 1rem;
    display: inline-block;
    color: #017369;
    -moz-border-radius: 0.625rem;
    -webkit-border-radius: 0.625rem;
    border-radius: 0.625rem;
}
.app-section .app-text .download-btn .ico {
    font-size: 2rem;
    margin-right: 0.5rem;
    display: inline-block;
}
.app-section .app-text .download-btn:hover {
    background-color: #ffe13e;
    text-decoration: none;
    box-shadow: 0.125rem 0.125rem 0.5625 rgba(53, 53, 53, 0.5);
    transition: all 0.3s ease;
}
.app-section .app-text p {
    color: #fff;
    font-size: 0.875rem;
}

@media only screen and (min-width: 768px) { 
    .app-section {
        flex-direction: row;
    }
    .app-section .app-img {
        width: 30%;
        margin: 0;
    }
    .app-section .app-img img {
        width: auto;
    }
    .app-section .app-text h5 {
        font-size: 1.875rem;
    }
    .app-section .app-text .download-btn {
        width: auto;
        font-size: 1.125rem;
        margin: 1rem 0.5rem;
        padding: 0.75rem 1.5rem;
    }
    .common-description {
        padding: 3rem 0;
    }
    .common-description h1 {
        font-size: 3rem;
    }
    .common-description p {
        font-size: 1.125rem;
    }
    .common-description h2 {
        font-size: 2rem;
    }
    .recyle-process h3 {
        font-size: 2rem;
    }
}

@media only screen and (min-width: 992px) {
    .community .banner h1 {
        font-size: 3.375rem;
        margin: 19rem 0 0 1rem;
    }
    .app-section .app-text {
        width: 80%;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1200px) {
    .app-section .app-text {
        width: 70%;
    }
}