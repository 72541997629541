.total-plastic-collected {
    background: rgb(41,177,162);
    background: linear-gradient(90deg, rgba(41,177,162,1) 0%, rgba(133,206,228,1) 100%);
    color: #FFF;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    margin-top: 1.5rem;
    padding: 1rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    justify-content: space-between;
    display: flex;
}
.plastic-collection {
    background: #F7F8F9;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    padding: 0.875rem 1rem;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.08);
    display: flex;
}
.plastic-collection .location {
    background: #009E8C;
    width: 2rem;
    height: 2rem;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.plastic-collection h3 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}
.plastic-collection h4, .plastic-collection .label, .plastic-collection span {
    color: #777;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    margin: 0;
}
.plastic-collection .label {
    font-weight: 500;
    display: inline-block;
}
.plastic-collection span {
    color: #000;
    padding: 0 1rem 0 0.5rem;
}