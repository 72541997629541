.document-card {
    background: #F4F4F4;
    padding: 1rem 1.5rem;
    box-shadow: 0.125rem 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.08);
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    justify-content: space-between;
    display: flex;
    margin-top: 30px;

}
.document-card h3 {
    color: #000;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
}
.document-card .green-text {
    color: #8EC63E;
    font-weight: 700;
}
.document-card .red-text {
    color: #FB304C;
    font-weight: 700;
}
.document-card .file-upload {
    width: 2rem;
    height: 2rem;
    position: absolute;
    opacity: 0;
}