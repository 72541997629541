.feedback-submitted {
    text-align: center;
    padding: 4.375rem 8rem 2.75rem 8rem;
}
.feedback-submitted h2 {
    color:  #009E8C;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.feedback-submitted p {
    color: #616161;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.75rem 3rem 3.5rem 3rem;
}
.feedback-submitted img {
    margin: 0 auto;
}
.feedback-submitted .green-btn {
    margin: 2.5rem 0 0 0;
}
.rate {
    width: 9.375rem;
    height: 2rem;
    padding: 0;
}
.rate:not(:checked) > input {
    position: absolute;
    top: -9999px;
}
.rate:not(:checked) > label {
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.875rem;
    line-height: 1.875rem;
    color: #ccc;
    margin: 0;
    float: right;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

.rate:not(:checked) div > input {
    position: absolute;
    top: -9999px;
}
.rate:not(:checked) div > label {
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1.875rem;
    line-height: 1.875rem;
    color: #ccc;
    margin: 0;
    float: right;
}
.rate:not(:checked) div > label:before {
    content: '★ ';
}
.rate div > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) div > label:hover,
.rate:not(:checked) div > label:hover ~ label {
    color: #deb217;  
}
.rate div > input:checked + label:hover,
.rate div > input:checked + label:hover ~ label,
.rate div > input:checked ~ label:hover,
.rate div > input:checked ~ label:hover ~ label,
.rate div > label:hover ~ input:checked ~ label {
    color: #c59b08;
}