.corporate .banner {
    background: url(../../asset/images/chbg.jpg) no-repeat;
    background-size: cover;
}
.corporate .banner h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 2rem 0 0 1rem;
}

@media only screen and (min-width: 992px) {
    .corporate .banner h1 {
        font-size: 3.375rem;
        margin: 19rem 0 0 1rem;
    }
}