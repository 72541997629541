.ticket-raised .grey-block {
    background: #F7F8F9;
    margin-top: 1.5rem;
    padding: 0.875rem 1rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.08);
    display: flex;
}

.ticket-raised .grey-block .location-icon {
    background: #009E8C;
    width: 2rem;
    height: 2rem;
    margin: 0 1rem 0 0;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ticket-raised .grey-block h2 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    /* 150% */
}

.ticket-raised .grey-block h3 {
    color: #777;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    font-size: 1rem;
}

.ticket-raised .grey-block .ticket-detail {
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.625rem 0 0 0;
    gap: 2.5rem;
    display: flex;
}

.ticket-raised .grey-block .ticket-detail label {
    color: #777;
    padding-right: 0.5rem;
    font-weight: 400;
}

.ticket-raised .grey-block .ticket-status {
    background: #FF7676;
    color: #FFF;
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0.75rem 0 0 0;
    padding: 0.5rem 1rem;
    -moz-border-radius: 6.25rem;
    -webkit-border-radius: 6.25rem;
    border-radius: 6.25rem;
}

.ticket-raised .grey-block .ticket-status.pending {
    background: #FF8B02;
}

.ticket-raised .grey-block .ticket-status.resolved {
    background: #6BA5FF;
}

.question-rating {
    width: calc(100% - 5rem);
}

.ticket_id_font {
    color: #000 !important;
    font-size: "12px";
    padding-left: 5px;
}

.rate-experience .checkbox-group {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.rate-experience .label {
    color: #000;
    font-size: 1rem;
    line-height: 1.25rem;
    text-align: left;
}