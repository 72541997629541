.mid-section {
    width: 100%;
    min-height: 100vh;
    padding: 3.5rem 3rem 3rem 3rem;
    overflow: auto;
}

.mid-section h1 {
    color: #000;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mid-section h2 {
    color: #6B6B6B;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.75rem 0 0 0;
}

.mid-section .grey-card {
    background: #F1F1F1;
    min-width: 32.2%;
    padding: 1rem;
    text-align: center;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: inline-block;
}

.mid-section .grey-card h3 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.mid-section .grey-card h4 {
    text-align: center;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 500;
    padding: 1rem 0 1.5rem 0;
    line-height: normal;
}

.mid-section .grey-card .details-btn,
.mid-section .lg-grey-card .details-btn {
    background: #FFF;
    color: #009E8C;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.5rem 1rem;
    -moz-border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    border-radius: 2.5rem;
    display: inline-block;
    transition: all 0.5s ease;
}

.mid-section .grey-card .details-btn:hover,
.mid-section .lg-grey-card .details-btn:hover {
    background: #009E8C;
    color: #fff;
}

.mid-section .lg-grey-card {
    background: #F1F1F1;
    width: 66%;
    padding: 0.75rem 1.5rem 1.25rem 1.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
}

.mid-section .lg-grey-card .details-btn {
    margin-top: 3rem;
}

.mid-section .lg-grey-card h4 {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
}

.right-section {
    background: #E2F2F4;
    max-width: 16rem;
    min-height: 100vh;
    overflow: auto;
    padding: 1.5rem 1.25rem;
}

.right-section::-webkit-scrollbar {
    width: 0.5rem;
}

.right-section::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.right-section::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.custom-date {
    background: #fff;
    width: 36.375rem;
    padding: 2.5rem 5rem 3rem 5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    position: relative;
}

.custom-date .close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
    cursor: pointer;
}

.custom-date h2 {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.375rem;
    font-weight: 500;
    padding: 0 0 3.5rem 0;
    text-align: center;
}

.custom-date label {
    color: #3E3E3C;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    margin: 0;
    padding: 0 0 0.5rem 0;
}

.custom-date .react-datepicker__input-container input {
    height: 2.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #DFDFDF;
}
.custom-date .ordered-btn, .custom-date .green-btn {
    width: 50%;
    padding: 0.875rem 3.5rem;
}