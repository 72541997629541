.milestone-initative {
    background: #008d80;
    padding: 6.5rem 0 2rem 0;
}
.milestone-initative .content-section {
    padding: 2rem 1rem 0 1rem;
}
.milestone-initative .content-section .info {
    width: 100%;
    vertical-align: top;
    display: inline-block;
}
.milestone-initative .content-section .carousel {
    width: 100%;
    margin: 1.5rem 0 0 0;
    display: inline-block;
}
.milestone-initative h1 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.milestone-initative h2, .milestone-initative p {
    color: #fff;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.875rem;
    font-weight: 400;
}
.milestone-initative h2 {
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.thumbnail-carousel .item img {
    width: 100%;
    height: auto;
    border: none;
}
.milestone-initative .thumbnail-carousel .arrow-left, .milestone-initative .thumbnail-carousel .arrow-right {
    color: #fff;
}
.milestone-initative .thumbnail-carousel .arrow-left {
    left: -0.625rem;
}
.milestone-initative .thumbnail-carousel .arrow-right {
    right: -0.625rem;
}
.video .bfcVideo {
    width: 100%;
}

@media only screen and (min-width: 768px) { 
    .milestone-initative {
        padding: 4rem 0 2rem 0;
    }
    .milestone-initative .content-section {
        padding: 2.25rem 1rem 0 1rem;
    }
    .milestone-initative .content-section h1 {
        color: #fff;
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 1rem;
    }
    .milestone-initative .thumbnail-carousel .arrow-left {
        left: -0.25rem;
    }
    .milestone-initative .thumbnail-carousel .arrow-right {
        right: -0.25rem;
    }
    .video .bfcVideo {
        height: 22rem;
    }
}

@media only screen and (min-width: 1200px) {
    .milestone-initative .content-section .info {
        width: 30%;
    }
    .milestone-initative .content-section .carousel {
        width: 70%;
        margin: 0;
    }
    .video .bfcVideo {
        height: 62.5rem;
    }
}