.total-resource {
    background: rgb(41,177,162);
    background: linear-gradient(90deg, rgba(41,177,162,1) 0%, rgba(133,206,228,1) 100%);
    color: #FFF;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    margin-top: 1.5rem;
    padding: 1rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    justify-content: space-between;
    display: flex;
}