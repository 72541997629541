.leaderboard-score {
    background: linear-gradient(91deg, #8685FF 0.46%, #B67EFF 99.44%);
    -moz-border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
    padding: 1rem 1.25rem;
    position: relative;
}
.leaderboard-score h1 {
    color: #FFF;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.leaderboard-score span {
    color: #FFF;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0.25rem 0 0.75rem 0;
    display: block;
}
.leaderboard-score .view-details {
    background: #FFF;
    color: #009E8C;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.3125rem 0.5rem 0.3125rem 0.75rem;
    -moz-border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    border-radius: 2.5rem;
    transition: all 0.5s ease;
}
.leaderboard-score .view-details:hover {
    background: #FEED42;
    color: #000;
}
.leaderboard-score .crown {
    position: absolute;
    bottom: 0;
    right: 0.75rem;
}