.profile-block {
    background: #F4F4F4;
    padding: 1.5rem;
    -moz-border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
    box-shadow: 0.125rem 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.12);
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.profile-block .user-img {
    width: 5.5rem;
    height: 5.5rem;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
.profile-block h3 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.25rem;
}
.profile-block .email {
    color: #6B6B6B;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: inherit;
}
.profile-block .profile-type {
    background: #FEED42;
    color: #000;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.9375rem;
    margin-top: 0.5rem;
    padding: 0.3125rem 1rem;
    -moz-border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    border-radius: 2.5rem;
    display: inline-block;
    transition: all 0.5s ease;
} 
.profile-block .green-btn {
    margin-left: 1rem;
}