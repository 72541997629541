.home-right-section {
    width: 100%;
    justify-content: center;
    display: flex;
}

.link-text {
    color: #156CEE;
    font-size: 1.25rem;
    line-height: 2.25rem;
    font-weight: 500;
}

.banner {
    background: url(../../asset/images/homebannermobile.jpg) no-repeat;
    background-size: cover;
    padding: 6.5rem 0 2rem 0;
}

.main-header {
    background: #fff200;
    height: 4rem;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    display: flex;
}

.main-header .logo {
    top: -140%;
    left: 1rem;
    position: absolute;
    z-index: 3;
}

.main-header .logo img {
    width: 8rem;
}

.main-header .has-tag {
    color: #01ad9d;
    font-size: 1.5rem;
}

.main-header .login-btn {
    background: #00a892;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    -moz-border-radius: 0.5625rem;
    -webkit-border-radius: 0.5625rem;
    border-radius: 0.5625rem;
    border: 0.125rem solid #fff;
    position: absolute;
    z-index: 1;
    right: 0.5rem;
    top: -4.75rem;
    transition: all 0.5s ease;
}

.main-header .login-btn:hover {
    background: #008271;
}

.banner h1 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 1.5rem 0 1.5rem 1rem;
}

.banner h1 span {
    display: block;
}

.banner .discover-btn {
    margin-left: 1rem;
}

.main-header .hamburger {
    color: #01ad9d;
    font-size: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    position: relative;
    z-index: 3;
}

.discover-btn {
    background: #fff200;
    padding: 0.5rem 2rem;
    color: #01ad9d;
    font-size: 1rem;
    -moz-border-radius: 0.625rem;
    -webkit-border-radius: 0.625rem;
    border-radius: 0.625rem;
    text-transform: uppercase;
    transition: all 0.5s ease;
    display: inline-block;
}

.discover-btn:hover {
    background: #01ad9d;
    color: #fff;
}

.home .divider {
    background: #fff102;
    height: 1rem;
}

.home .programmee {
    width: 100%;
    position: relative;
}

.home .programme-banner, .home .programme-banner-mobile {
    width: 100%;
    display: none;
}

.home .programme-banner-mobile {
    display: block;
}

.home .programmee .transparent {
    background: rgba(79, 160, 148, 0.35);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.home .programmee h2 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    padding: 25% 0 0 1rem;
    text-shadow: 0.0625rem 0.0625rem 0.1875rem #000000;
}

.home .programmee p {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    padding-bottom: 1rem;
    padding-left: 1rem;
    text-shadow: 0.0625rem 0.0625rem 0.1875rem #000000;
}
.home .programmee .discover-btn {
    margin-left: 1rem;
}

.home .category-section {
    position: relative;
}

.home .category-section h3,
.home .category-section h3 .count {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    margin-right: 0.625rem;
}

.home .category-section h3 {
    margin-top: 1rem;
}

.home .category-section h3 span {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    display: block;
}

.home .category-section h3 .count {
    font-size: 1.5rem;
    display: inline-block;
}

.home .category-section .transparent {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 1.5rem 1rem 0 1rem;
}

.footer {
    background: #2c665f;
    width: 100%;
    padding: 1rem;
}

.footer li {
    color: #fff;
    padding-bottom: 0.3125rem;
}

.footer li a {
    color: #fff;
    font-size: 0.875rem;
}

.footer li a:hover {
    color: #fff200;
}

.footer .social-icon {
    display: inline-block;
}

.footer .social-icon .ico {
    width: 1.875rem;
    margin-right: 1rem;
}

.footer .social-icon .ico:hover {
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    box-shadow: 0 0 0.1875rem 0.0625rem #FFF200
}

.footer-bottom {
    background: #294a46;
    padding: 0.625rem 0;
    text-align: center;
}

.footer-bottom li {
    padding: 0 0.5rem;
    border-right: 0.0625rem solid #fff;
    display: inline-block;
}

.footer-bottom li:last-child {
    border: none;
}

.footer-bottom li,
.footer-bottom li a {
    color: #fff;
    font-size: 0.875rem;
}

.footer-bottom li a:hover {
    color: #fff200;
}
.forgot-password-modal, .forgot-password-otp-modal {
    background: #fff;
    width: 24rem;
    padding: 1rem 2rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.4);
}
.forgot-password-otp-modal {
    width: 64rem;
    padding: 0;
    overflow: hidden;
}
.forgot-password-modal h1 {
    color: rgb(0, 169, 144);
    font-size: 1.25rem;
    text-align: center;
    font-weight: 500;
}
.form-group {
    display: flex;
    align-items: center;
}
.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}
.form-group label {
    position: relative;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin: 0 0.25rem 0 0;
}
.form-group label:before {
    content:'';
    width: 1rem;
    height: 1rem;
    -webkit-appearance: none;
    background-color: transparent;
    border: 0.0625rem solid #DFDFDF;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 0.25rem;
    -moz-border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
}
.form-group input:checked + label:before {
    background: #01ad9d;
    border-color: #01ad9d;
}
.form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 0.125rem;
    left: 0.3125rem;
    width: 0.3125rem;
    height: 0.625rem;
    border: solid #fff;
    border-width: 0 0.125rem 0.125rem 0;
    transform: rotate(45deg);
}

@media only screen and (min-width: 768px) {
    .banner {
        background: url(../../asset/images/homebanner.gif) no-repeat;
        background-size: 100%;
        padding: 4rem 0 8rem 0;
    }
    .main-header .logo {
        top: -80%;
    }
    .main-header .logo img {
        width: auto;
    }
    .main-header .login-btn {
        padding: 0.625rem 1.5rem;
        font-size: 1.125rem;
        top: -3.5rem;
    }
    .banner h1 {
        font-size: 5rem;
        margin: 10rem 0 2rem 1rem;
    }
    .home .divider {
        height: 2rem;
    }    
    .home .programmee h2 {
        font-size: 5rem;
        padding: 15% 0 0 1rem;
    }
    .home .programmee p {
        font-size: 1.375rem;
        padding-bottom: 1.875rem;
    }
    .discover-btn {
        padding: 0.625rem 2.1875rem;
        font-size: 1.125rem;
    }
    .home .category-section h3,
    .home .category-section h3 .count {
        font-size: 5rem;
    }
    .home .category-section h3 {
        margin-top: 2rem;
    }
    .home .category-section h3 span {
        font-size: 1.375rem;
    }
}

@media only screen and (min-width: 992px) {
    .home .category-section .transparent {
        padding-top: 0;
    }
    .home .programme-banner {
        display: block;
    }
    .home .programme-banner-mobile {
        display: none;
    }
    .column-pad {
        padding-right: 20%;
    }
}

@media only screen and (min-width: 1200px) {
    .home .category-section .transparent {
        padding: 5rem 0 0 0;
    }
}