.feedback-given {
    background: #fff;
    margin: 1.5rem 0 0 0;
    padding: 1.25rem 1rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.12);
    display: flex;
}
.feedback-given h2 {
    color: #009E8C;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
}
.feedback-given .date {
    color: #000;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
}
.feedback-given p {
    color: #424242;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; 
    padding: 0.5rem 0;
}
.feedback-given .status {
    background: #1793E8;
    color: #FFF;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.5rem 1rem;
    -moz-border-radius: 6.25rem;
    -webkit-border-radius: 6.25rem;
    border-radius: 6.25rem;
    display: inline-block;
}
.feedback-given .status.acknowledge {
    background: #9747FF;
}
.feedback-given .status.under-process {
    background: #FF8B02;
}