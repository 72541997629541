.resource-card {
    background: #F7F8F9;
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem 1rem 1.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0.25rem 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.12);
    display: flex;
}
.resource-card .location {
    background: #009E8C;
    width: 2rem;
    height: 2rem;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.resource-card h2 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}
.resource-card .info, .resource-card .info label {
    color: #929292;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
}
.resource-card .info {
    padding: 0.5rem 2.5rem 0 0;
}
.resource-card .info label {
    color: #424242;
    margin: 0;
    display: inline-block;
}
.resource-card .green-btn {
    color: #FFF;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding: 0.375rem 1rem;
}