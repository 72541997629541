.checkout-modal {
    text-align: center;
    padding: 2.75rem 3rem;
}
.checkout-modal h2 {
    color:  #009E8C;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.checkout-modal {
    text-align: center;
    padding: 2.75rem 3rem;
}
.checkout-modal h2 {
    color:  #009E8C;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.otp-verification-code {
    width: 62%;
    margin: 3rem auto 0 auto;
    text-align: center;
}
.otp-verification-code h2 {
    color: #00A990;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: 500;
}
.otp-verification-code p, .otp-verification-code label {
    color: #616161;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.5rem 0 0 0;
}
.otp-verification-code .otp-field {
    margin: 1rem 0 3rem 0;
    gap: 0.5rem;
    justify-content: center;
    display: flex;
}
.otp-verification-code .otp-field input {
    background: #FFF;
    width: 2.5rem;
    height: 2.5rem;
    -moz-border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid #BCBCBC;   
}
.otp-verification-code p span {
    color: #00A990;
    font-weight: 500;
}