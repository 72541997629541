.privacy-policy .banner {
    background: none;
    padding: 6.5rem 0 2rem 0;
}
.privacy-policy h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.privacy-policy p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
    padding: 0 1rem;
}
.privacy-policy h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.3125rem;
    margin-top: 2.5rem;
    padding: 0 1rem;
}
.privacy-policy ul {
    padding: 0 0 1rem 2.5rem;
    list-style: disc;
}
.privacy-policy ul li {
    font-size: 1rem;
    margin: 0.25rem 0;
}

@media only screen and (min-width: 768px) {
    .privacy-policy .banner {
        padding: 4rem 0;
    }
    .privacy-policy h1 {
        font-size: 3rem;
    }
}