.profile-card {
    background: #fff;
    padding: 1.375rem 1.6875rem;
    -moz-border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
    text-align: center;
}
.profile-card .avatar {
    margin: 0 auto;
}
.profile-card h1 {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 1rem 0 0.5rem 0;
}
.profile-card .email {
    color: #6B6B6B;
    font-size: 0.8125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.profile-card .profile-type {
    background: #FEED42;
    color: #000;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.9375rem;
    padding: 0.3125rem 1rem;
    -moz-border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    border-radius: 2.5rem;
    display: inline-block;
    transition: all 0.5s ease;
} 