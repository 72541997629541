.terms-condition {
    padding-bottom: 2rem;
}
.terms-condition .banner {
    background: none;
    padding: 6.5rem 0 2rem 0;
}
.terms-condition h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
}
.terms-condition p {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
    padding: 0 1rem;
}
.terms-condition p.mb-0 {
    margin-bottom: 0;
}
.terms-condition h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.3125rem;
    margin-top: 2.5rem;
    padding: 0 1rem;
}
.terms-condition ul {
    padding: 0 1rem 1rem 2.5rem;
    list-style: disc;
}
.terms-condition ul.roman {
    list-style-type: upper-roman;
}
.terms-condition ul li {
    font-size: 1rem;
    margin: 0.25rem 0;
}

@media only screen and (min-width: 768px) {
    .terms-condition .banner {
        padding: 4rem 0;
    }
    .terms-condition h1 {
        font-size: 3rem;
    }
}