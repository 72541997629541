.not-found {
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.not-found h1 {
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
}