.upload-document {
    background: url(../../asset/images/upload-document-bg.png) no-repeat #D1EEFF;
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    display: inline-block;
}
.upload-document .header {
    background: #00A990;
    padding: 1.5rem 5rem;
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: normal;
}
.upload-document .header .logout {
    color: #FFF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: absolute;
    top: 1.5rem;
    right: 5rem;
    align-items: center;
    display: flex;
}
.upload-document .header .logout svg {
    margin-right: 0.75rem;
}
.upload-document .white-block {
    background: #fff;
    width: 50rem;
    margin: 4rem auto;
    padding: 2.75rem 7.5rem;
    -moz-border-radius: 1rem;
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
}
.upload-document .white-block .green-btn {
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
}