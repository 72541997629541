.help-mid-section::-webkit-scrollbar {
    width: 0.5rem;
}

.help-mid-section::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.help-mid-section::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.help-mid-section {
    width: 100%;
    height: 100vh;
    padding: 3.5rem 3rem 3rem 3rem;
    overflow: auto;
}

.help-mid-section h1 {
    color: #000;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.help-mid-section h2 {
    color: #6B6B6B;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0.75rem 0 0 0;
}

.help-mid-section .help-grey-card {
    background: #F1F1F1;
    min-width: 48%;
    padding: 1rem;
    text-align: left;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    box-sizing: border-box;
    display: inline-block;
}

.help-mid-section .help-grey-card h3 {
    color: #000;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
}

.help-mid-section .help-grey-card h4 {
    text-align: left;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    padding: 1rem 0 1.5rem 0;
    line-height: normal;
}

.help-mid-section .help-grey-card .details-btn,
.help-mid-section .lg-help-grey-card .details-btn {
    background: #009E8C;
    color: #fff;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.5rem 1rem;
    -moz-border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    transition: all 0.5s ease;
}

.help-mid-section .help-grey-card .details-btn:hover,
.help-mid-section .lg-help-grey-card .details-btn:hover {
    background: #009E8C;
    color: #fff;
}

.help-mid-section .lg-help-grey-card {
    background: #F1F1F1;
    width: 66%;
    padding: 0.75rem 1.5rem 1.25rem 1.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
}

.help-mid-section .lg-help-grey-card .details-btn {
    margin-top: 3rem;
}

.help-mid-section .lg-help-grey-card h4 {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
}

.explore_programme_image {
    width: 100px;
}

.location-map {
    background: #fff;
    padding: 3rem;
    -moz-border-radius: 0.75rem;
    -webkit-border-radius: 0.75rem;
    border-radius: 0.75rem;
    position: relative;
}

.location-map .close {
    position: absolute;
    z-index: 1;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
}