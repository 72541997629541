faq.css

/* Style for the entire FAQ component */
.flex {
     display: flex;
   }
   
   .flex-row {
     flex-direction: row;
   }
   
 
   
   /* Style for the left and right sections */
   .left-section,
   .right-section {
     padding: 20px;
   }
   
   /* Style for the FAQ mid-section */
   .help-mid-section {
     padding: 20px;
   }
   
   /* Style for FAQ title */
   h1 {
     font-size: 24px;
     font-weight: bold;
     margin-bottom: 20px;
   }
   
   /* Style for the FAQ card */
   .faq-card {
     background-color: #efefef;
     border-radius: 10px;
     padding: 15px;
     margin-bottom: 15px;
     /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
     width: 97%;
   }
   
   
   .faq-question.active {
     background-color: #5eadb9;
     color: #fff;
   }

   .dropdown-icon {
     width: 20px; /* Adjust the width as needed */
     height: 20px; /* Adjust the height as needed */
     transition: transform 0.3s ease; /* Smooth transition for the rotation effect */
   }
   
   .dropdown-icon.active {
     transform: rotate(180deg); /* Rotate the icon when the button is active */
   }
   
   /* Style for the FAQ question button */
   .faq-question {
     background-color:#def2f5 ;
     color: #000;
     padding: 10px;
     border: none;
     border-radius: 5px;
     cursor: pointer;
     font-weight:500;
     width: 97%;
     margin-bottom: 10px;
     text-align: left;
   }
   
   /* Style for the FAQ answer */
   .faq-answer {
     margin-top: 10px;
     color: #000;
     font-weight: 500;
   }
   
   