.about-pet {
    overflow: hidden;
}
.about-pet .banner {
    background: url(../../asset/images/plasticbg.jpg) no-repeat;
    background-size: cover;
}
.about-pet .banner h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 4rem 1rem 0 1rem;
}
.about-pet .description {
    text-align: center;
    padding: 2rem 1rem;
}
.about-pet .description h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
}
.about-pet .description p {
    font-size: 0.875rem;
    margin: 1rem 0 0 0;
}
.about-pet .description h3 {
    font-size: 0.875rem;
    font-weight: 300;
    margin-top: 1rem;
    margin-bottom: 1.875rem;
    font-weight: 400;
}
.about-pet .process-step {
    background: #01ad9d;
    height: 100%;
    padding: 1.5rem;
}
.about-pet .process-step h4 {
    color: #fff;
    margin-top: 0;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
}
.about-pet .process-step h4 span {
    color: #a8e8e2;
}
.about-pet .process-step h5 {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 1.5rem;
}
.about-pet .process-step p {
    color: #fff;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
}
.about-pet .process-step.white {
    background: #fff;
}
.about-pet .process-step.white h4, .about-pet .process-step.white h5 {
    color: #000;
}
.about-pet .process-step.white h4 span {
    color: #979797;
}
.about-pet .process-step.white p {
    color: #000;
}
.recycled-plastic, .recycle-plastic-reason {
    width: 90%;
    margin: 0 auto;
    padding: 2rem 0;
}
.recycled-plastic h6, .recycle-plastic-reason h6 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-align: center;
}
.recycled-plastic ul, .recycle-plastic-reason ul {
    gap: 1.5rem;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}
.recycled-plastic ul li {
    width: calc(25% - 1.5rem);
}
.recycle-plastic-reason ul {
    justify-content: center;
}
.recycle-plastic-reason ul li {
    width: calc(33% - 1.5rem);
}
.recycle-plastic-reason ul li span {
    font-size: 1.125rem;
    margin-top: 1.25rem;
    display: block;
}
.recycle-plastic-reason ul li {
    text-align: center;
}
.recycle-plastic-reason ul li img {
    display: inline-block;
}

@media only screen and (min-width: 992px) { 
    .about-pet .banner h1 {
        font-size: 3.375rem;
        margin: 22rem 1rem 0 1rem;
    }
    .about-pet .description {
        padding: 3rem 1rem;
    }
    .about-pet .description h2 {
        font-size: 3rem;
    }
    .about-pet .description p {
        font-size: 1.125rem;
    }
    .about-pet .description h3 {
        font-size: 1.625rem;
    }
    .about-pet .process-step h4 {
        font-size: 2.25rem;
    }
    .about-pet .process-step h5 {
        font-size: 1.375rem;
        margin-bottom: 2.5rem;
    }
    .about-pet .process-step p {
        font-size: 1.125rem;
        line-height: 1.875rem;
    }
    .recycled-plastic, .recycle-plastic-reason {
        width: 80%;
        padding: 5rem 0;
    }
    .recycled-plastic h6, .recycle-plastic-reason h6 {
        font-size: 3rem;
    }
}
@media only screen and (min-width: 1200px) {
    .about-pet .process-step {
        padding: 6.25rem;
    }
}