.forgot-password, .verification-code, .reset-password, .reset-successful {
    width: 60%;
    margin: 6rem auto 8rem auto;
}
.reset-password, .reset-successful {
    width: 40%;
}
.forgot-password h2, .verification-code h2, .reset-password h2, .reset-successful h2 {
    color: #00A990;
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}
.forgot-password p, .verification-code p, .reset-password p, .reset-successful p {
    color: #616161;
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.5rem 0 0 0;
}
.verification-code p span {
    color: #009E8C;
    font-weight: 500;
}
.forgot-password label {
    color: #3E3E3C;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
}
.verification-code .otp-field {
    margin: 1rem 0 3rem 0;
    gap: 0.5rem;
    justify-content: center;
    display: flex;
}
.verification-code .otp-field input {
    background: #FFF;
    width: 2.5rem;
    height: 2.5rem;
    -moz-border-radius: 0.25rem;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem;
    border: 0.0625rem solid #BCBCBC;   
}
.reset-password .form-control {
    padding-right: 2.5rem;
}
.reset-password .eye-icon {
    position: absolute;
    top: 2.5rem;
    right: 0.75rem;
    cursor: pointer;
}