.sidebar {
    background: #00A990;
    width: 100%;
    min-height: 100%;
    position: relative;
}
.sidebar .elipse-top {
    position: absolute;
    top: 0;
}
.sidebar .elipse-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
}
.sidebar .logo {
    width: 100%;
    padding: 2.25rem 0 2.625rem 0;
    justify-content: center;
    position: relative;
    z-index: 1;
    display: flex;
}
.sidebar ul {
    position: relative;
    z-index: 1;
}
.sidebar li {
    margin-bottom: 1.5rem;
}
.sidebar li a {
    width: 100%;
    padding: 0.75rem 1.5rem;
    color: #FFF;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.875rem;
    align-items: center;
    display: flex;
    transition: all 0.5s ease;
}
.sidebar li a svg {
    margin-right: 0.5rem;
}
.sidebar li a:hover, .sidebar li.active a {
    background: #FEED42;
    color: #00A990;
}
.sidebar li a:hover svg {
    filter: brightness(0) saturate(100%) invert(46%) sepia(23%) saturate(3699%) hue-rotate(140deg) brightness(91%) contrast(101%);
}
.sidebar::-webkit-scrollbar {
    width: 0.5rem;
}
.sidebar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.logout-modal {
    background: #fff;
    width: 43.75rem;
    padding: 5.25rem 3rem 3rem 3rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}
.logout-modal h1 {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.375rem;
    font-weight: 400;
}
.logout-modal .ordered-btn, .logout-modal .green-btn {
    padding: 0.875rem 3.5rem;
}