.about-bfc {
    overflow-x: hidden;
}
.about-bfc .banner {
    background: url(../../asset/images/bfcbg.jpg) no-repeat;
    background-size: cover;
}
.about-bfc .banner h1 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 4rem 1rem 0 1rem;
}
.about-bfc .common-description h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
}
.order-1 {
    order: 1;
}
.order-2 {
    order: 2;
}
.about-bfc .step {
    flex-direction: column;
    display: flex;
}
.about-bfc .step .left-col {
    width: 100%;
    padding: 1rem 0 0 0;
}
.about-bfc .step .left-col h3 {
    color: #008d80;
    font-size: 6.25rem;
    padding: 0 1.25rem 0 2rem;
    font-weight: 700;
    margin: 0;
    display: inline-block;
}
.about-bfc .step .left-col p {
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    width: 45%;
    padding-top: 1rem;
    display: inline-block;
}
.about-bfc .right-col {
    width: 100%;
}
.about-bfc .wyc-section {
    background: #feed02;
    margin-top: 1.5rem;
    padding: 2rem 0;
}
.about-bfc .wyc-section h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-align: center;
}
.about-bfc .initiative-section {
    gap: 1.5rem;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
.about-bfc .initiative-section .discover-btn {
    text-align: center;
}
.about-bfc .wyc-section img {
    width: 90%;
    margin: 0 auto;
}
.about-bfc .video-section {
    background: url(../../asset/images/video-bg.png) no-repeat left bottom #019f87;
    padding: 2rem 0 30rem 0;
}
.about-bfc .video-section .video-iframe {
    width: 90%;
    margin: 0 auto;
    height: 25rem;
}
.about-bfc .video-section .vcx {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
}
.about-bfc .video-section .vcx .ico {
    width: 15%;
    display: inline-block;
}
.about-bfc .video-section .vcx .content {
    width: 80%;
    vertical-align: top;
    padding-left: 1.875rem;
    display: inline-block;
}
.about-bfc .video-section .vcx .content h2 {
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0 0 0.625rem 0;
}
.about-bfc .video-section .vcx .content p {
    color: #fff;
    font-size: 1rem;
    padding-bottom: 1.875rem;
}

@media only screen and (min-width: 768px) {
    .order-1 {
        order: 2;
    }
    .order-2 {
        order: 1;
    }
    .about-bfc .step {
        flex-direction: row;
    }
    .about-bfc .step .left-col {
        width: 60%;
        padding: 2rem 0 0 0;
    }
    .about-bfc .bfc-line {
        background: #fff200;
        width: 100%;
        height: 0.1875rem;
    }
    .about-bfc .right-col {
        width: 40%;
    }
    .about-bfc .initiative-section {
        flex-direction: row;
    }
}

@media only screen and (min-width: 992px) {
    .about-bfc .banner h1 {
        font-size: 3.375rem;
        margin: 19rem 0 0 0;
    }
    .about-bfc .common-description h2 {
        font-size: 3rem;
    }
    .about-bfc .wyc-section {
        padding: 3rem 0;
    }
    .about-bfc .step .left-col {
        padding: 4rem 0 0 0;
    }
    .about-bfc .wyc-section h3 {
        font-size: 3rem;
    }
    .about-bfc .wyc-section img {
        width: 60%;
    }
    .about-bfc .video-section {
        padding: 5rem 0 30rem 0;
    }
    .about-bfc .video-section .video-iframe {
        width: 60%;
        margin: 0;
    }
    .about-bfc .video-section .vcx {
        padding: 2rem 0 0 0;
    }
    .about-bfc .video-section .vcx .content h2 {
        font-size: 2rem;
        padding: 1.25rem 0 0.625rem 0;
    }
}

@media only screen and (min-width: 1200px) {
    .about-bfc .step .left-col {
        padding: 10rem 0 0 0;
    }
}