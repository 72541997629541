body::-webkit-scrollbar {
    width: 0.5rem;
}
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.text-blue {
    color: #1793E8 !important;
}
.text-orange {
    color: #FF8B02 !important;
}
.text-green {
    color: #009E8C !important;
}
.text-purple {
    color: #9747FF !important;
}
.text-pink {
    color: #FF6586 !important;
}
label {
    color: #3E3E3C;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem;
    margin-bottom: 0.5rem;
    display: block;
}
.form-control {
    background: #FFF;
    width: 100%;
    padding: 0.7rem 0.875rem;
    border: 0.0625rem solid #DFDFDF;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    display: flex;
}
.ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.4) !important;
    z-index: 2;
}
.yellow-btn {
    background: #FEED42;
    color: #000;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 0.9375rem;
    padding: 0.3125rem 1rem;
    -moz-border-radius: 2.5rem;
    -webkit-border-radius: 2.5rem;
    border-radius: 2.5rem;
    display: inline-block;
    transition: all 0.5s ease;
}   
.yellow-btn:hover {
    background: #f1de23;
}
.green-btn {
    background: #00A990;
    color: #FFF;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.75rem 1.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    transition: all 0.5s ease;
}
.green-btn:hover {
    background: #028a76;
}
.ordered-btn {
    background: #fff;
    color: #00A990;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0.75rem 1.5rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
    border: 0.0625rem solid #00A990;
    display: inline-block;
}
.mid-wrap {
    width: 100%;
    min-height: 100vh;
    padding: 3.5rem 3rem 3rem 3rem;
}
.mid-wrap h1 {
    color: #000;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
}
.mid-wrap h1 img {
    margin-right: 1rem;
}
.modal {
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    justify-content: center;
    align-items: center;
    display: flex;
}
.modal .modal-dialog {
    background: #FFF;
    max-width: 43.75rem;
    -moz-border-radius: 0.5rem;
    -webkit-border-radius: 0.5rem;
    border-radius: 0.5rem;
}
.tab-buttons {
    border-bottom: 1px solid #DDDBDA;
}
.tab-buttons button {
    color: #080707;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    padding: 0.75rem;
}
.tab-buttons button.active {
    font-weight: 500;
    box-shadow: 0px -3px 0px 0px #009E8C inset;
}
.custom-dropdown {
    background: url(../src/asset/images/upArrow.png) no-repeat;
    background-position: 0 0.8125rem;
    color: #191D15;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0.75rem 0.75rem 0.75rem 2rem;
    border: none;
}
.custom-dropdown:focus-visible {
    outline: none;
}
.menu-section {
    background: #fef200;
    width: 100%;
    height: 100%;
    padding: 7rem 0 3rem 0;
    position: fixed;
    z-index: 2;
    overflow: auto;
}
.menu-section li {
    padding: 0.875rem 0;
    text-align: center;
    border-bottom: 0.0625rem solid #01ad9d;
}
.menu-section li.dropdown {
    padding-bottom: 0;
}
.menu-section li a {
    color: #01ad9d;
    font-size: 1rem;
    font-weight: 700;
}
.menu-section li:first-child {
    border-top: 0.0625rem solid #01ad9d;
}
.menu-section li ul {
    background: #f9f9f9;
    margin-top: 1.5rem;
}

@media only screen and (min-width: 768px) { 
    .menu-section {
        padding: 13rem 0 3rem 0;
    }
    .menu-section li {
        padding: 1.5rem 0;
    }
    .menu-section li a {
        font-size: 1.375rem;
    }
}
